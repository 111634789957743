<template>
  <Dialog
    icon="insert_chart"
    tooltip="Show Chart"
    :title="title"
    :body="{
      component: plotlyChart,
      props: {
        data: chartData,
        layout: chartLayout,
      },
    }"
    :loading="isGetForecastPending"
    @open="findData()"
    @close="removeData()"
  >
    <template #toolbar>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col sm="4" class="pt-6">
        <v-select v-model="granularity" :items="granularityItems"> </v-select>
      </v-col>
    </template>
  </Dialog>
</template>

<script>
import { makeGetMixin } from "feathers-vuex";
import { mapActions, mapMutations } from "vuex";
import Chart from "@/components/charts/Plotly";

export default {
  name: "DialogForecastOutput",
  components: {
    Dialog: () => import("@/components/Dialog"),
  },
  mixins: [
    makeGetMixin({
      service: "forecasts",
      watch: "fetchParams",
      queryWhen: "isOpen",
    }),
  ],
  props: {
    itemId: { type: [String, Number], required: true },
    outputType: { type: String, required: true },
    title: { type: String, required: false, default: "" },
  },
  data() {
    return {
      isOpen: false,
      granularity: "annual",
      chartLayout: {
        xaxis: {
          autorange: true,
          rangeslider: {},
          type: "date",
        },
        autosize: true,
      },
      granularityOrder: ["annual", "monthly", "weekly", "daily"],
      hasOutput: false,
      data: [],
    };
  },
  computed: {
    plotlyChart() {
      return Chart;
    },
    granularityItems() {
      return this.hasOutput
        ? Object.keys(this.forecast.output)
            .map((v) => ({
              text: v.toLowerCase().replace(/\b[a-zA-Z]/g, (word) => word.toUpperCase()),
              value: v,
            }))
            .sort(
              (a, b) =>
                this.granularityOrder.findIndex((item) => item === a.value) -
                this.granularityOrder.findIndex((item) => item === b.value)
            )
        : [];
    },
    forecastId() {
      return this.itemId;
    },
    forecastParams() {
      return { query: {} };
    },
    forecastFetchParams() {
      return { query: { $select: ["id", "output"] } };
    },
    chartData() {
      if (this.hasOutput) {
        if (this.outputType === "energy_prices") {
          return this.energyChartData(this.forecast.output);
        }
        if (this.outputType === "reserves") {
          return this.reservesChartData(this.forecast.output);
        }
      }
      return [];
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      getOutput: "forecasts/get",
    }),
    ...mapMutations({
      clearStore: "forecasts/removeItem",
    }),
    findData() {
      this.isOpen = true;
      this.getForecast().then((res) => {
        if (res.output) {
          this.hasOutput = true;
        }
      });
    },
    removeData() {
      this.isOpen = false;
      this.clearStore(this.itemId);
      this.hasOutput = false;
      this.$emit("close");
    },
    energyChartData(output) {
      return [
        {
          x: output[this.granularity].map((v) => v.timestamp),
          y: output[this.granularity].map((v) => v.energy_price.high),
          name: "High",
        },
        {
          x: output[this.granularity].map((v) => v.timestamp),
          y: output[this.granularity].map((v) => v.energy_price.base),
          name: "Base",
        },
        {
          x: output[this.granularity].map((v) => v.timestamp),
          y: output[this.granularity].map((v) => v.energy_price.low),
          name: "Low",
        },
      ];
    },
    reservesChartData(output) {
      const data = [];
      const reservesData = {};
      if (output) {
        Object.entries(output[this.granularity][0].reserves).forEach((reserve) => {
          reservesData[reserve[0]] = [];
        });
        output[this.granularity].forEach((element) => {
          Object.entries(element.reserves).forEach((reserve) => {
            reservesData[reserve[0]].push({
              timestamp: element.timestamp,
              ...reserve[1],
            });
          });
        });

        Object.entries(reservesData).forEach((element) => {
          data.push(
            {
              x: element[1].map((v) => v.timestamp),
              y: element[1].map((v) => v.low),
              name: `${element[0].replace(/\b[a-zA-Z]/g, (word) => word.toUpperCase())} - Low`,
            },
            {
              x: element[1].map((v) => v.timestamp),
              y: element[1].map((v) => v.base),
              name: `${element[0].replace(/\b[a-zA-Z]/g, (word) => word.toUpperCase())} - Base`,
            },
            {
              x: element[1].map((v) => v.timestamp),
              y: element[1].map((v) => v.high),
              name: `${element[0].replace(/\b[a-zA-Z]/g, (word) => word.toUpperCase())} - High`,
            }
          );
        });
        return data.sort((a, b) => (a.name.split(" -")[0] > b.name.split(" -")[0] ? 1 : -1));
      }
      return data;
    },
  },
};
</script>

<style></style>
